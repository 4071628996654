@import '../../responsive-nlc';

.delivery-popin {
  overflow: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &__body {
    padding: 0 25px;

    @include respond-to-tablet {
      padding: 0 15px;
    }
  }
}
