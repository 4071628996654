@import '../../responsive-nlc';

.delivery-popin__body {
  @include respond-to-mobile() {
    .cbm--onlyDesktop {
      display: none;
    }

    .delivery--popin {
      &-geolocalisationButtons {
        flex-direction: column-reverse;
      }
    }
  }

  @include respond-to-desktop-and-tablet() {
    .cbm--onlyMobile {
      display: none;
    }
  }

  .delivery--popin {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;
      border-bottom: 1px solid var(--colors-secondary-xsoft);
    }

    &-prices {
      margin-top: 9px;
      margin-bottom: 20px;
    }

    &-content {
      margin-bottom: 20px;
    }

    &-geolocalisationButtons {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.zipcode_input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
